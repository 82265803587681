<template>
    <div>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-7">
          <h5 class="blue-text font-weight-bold" v-if="usersData">
            <router-link
              :to="{
                name: 'adminRoute-users-info',
                params: {
                  id: $route.params.id,
                },
              }"
            >
              <font-awesome-icon
                class="mr-3"
                :icon="{ prefix: 'fas', iconName: 'arrow-left' }"
              />
            </router-link>
            {{ formatUserName(usersData) }}様のサブスクリプション履歴
          </h5>
        </div>
        <div class="col-12">
          <div id="staging-list" class="mt-5" v-if="stagingListing">
            <div v-if="false">
              <div
                class="row m-0 pt-3 pb-3 mb-2 item"
                v-for="(el, i) in stagingListing"
                :key="i"
              >
                <div class="col-12 col-md-12 col-lg-12 col-xl-9">
                  <div class="row details">
                    <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                      <div class="row">
                        <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                          <p class="mb-1 grey-text font-weight-bold">注文ID</p>
                          <p class="ellipsis-1">{{ el.reference_no }}</p>
                        </div>
                        <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                          <p class="mb-1 grey-text font-weight-bold">注文する</p>
                          <p class="ellipsis-1" v-if="el.user.user_profile">
                            {{ formatUserName(el.user) }}
                          </p>
                        </div>
                        <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                          <p class="mb-1 grey-text font-weight-bold">枚数</p>
                          <p class="ellipsis-1">
                            {{
                              el.request_items.length === 1
                                ? `${el.request_items.length} 枚`
                                : `${el.request_items.length} 枚`
                            }}
                          </p>
                        </div>
                        <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                          <p class="mb-1 grey-text font-weight-bold">注文日</p>
                          <p class="ellipsis-1">
                            {{
                              el.billing ? formatDate(el.billing.updated_at) : 'N/A'
                            }}
                          </p>
                        </div>
                        <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                          <p class="mb-1 grey-text font-weight-bold">合計金額</p>
                          <p class="ellipsis-1">
                            {{
                              el.billing ? formatPrice(el.billing.amount) : 'N/A'
                            }}
                          </p>
                        </div>
                        <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                          <p class="mb-1 grey-text font-weight-bold">注文状況</p>
                          <p class="ellipsis-1 green-text" v-if="el.status === 3">
                            <font-awesome-icon
                              :icon="{ prefix: 'fas', iconName: 'circle' }"
                            />
                            <span class="ml-2">確認済み</span>
                          </p>
                          <p
                            class="ellipsis-1 yellow-text"
                            v-else-if="el.status === 2"
                          >
                            <font-awesome-icon
                              :icon="{ prefix: 'fas', iconName: 'circle' }"
                            />
                            <span class="ml-2">納品済み</span>
                          </p>
                          <p
                            class="ellipsis-1 grey-text"
                            v-else-if="el.status === 6"
                          >
                            <font-awesome-icon
                              :icon="{ prefix: 'fas', iconName: 'circle' }"
                            />
                            <span class="ml-2">キャンセル</span>
                          </p>
                          <p class="ellipsis-1 red-text" v-else>
                            <font-awesome-icon
                              :icon="{ prefix: 'fas', iconName: 'circle' }"
                            />
                            <span class="ml-2">納品前</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-12 col-lg-12 col-xl-3">
                  <div class="row actions">
                    <div class="col-12 mb-3">
                      <router-link
                        :to="{
                          name: 'adminRoute-users-order-history-view',
                          params: {
                            id: el.id,
                          },
                        }"
                      >
                        <button class="btn btn-ds blue white-text" v-waves.light>
                          詳細を表示
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <pagination
                class="mt-3 mb-0"
                :data="pagination.data"
                :limit="pagination.limit"
                :show-disabled="pagination.showDisabled"
                :size="pagination.size"
                :align="pagination.align"
                @pagination-change-page="getResults"
              />
            </div>
            <div v-else>
              <b-card class="shadow-1 pt-5 pb-5 text-center" no-header no-footer>
                <img src="/img/icons/ico-info.svg" alt="ico-info" />
                <h3 class="m-0 mt-3 grey-text">表示できる内容がありません。</h3>
              </b-card>
            </div>
          </div>
          <div class="mt-5" v-else>
            <div class="row m-0 pt-3 pb-3 item" v-for="(el, i) in 10" :key="i">
              <div class="col-12 col-md-12 col-lg-12 col-xl-9">
                <div class="row details">
                  <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                    <div class="row">
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-4">
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="80%"></b-skeleton>
                      </div>
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-4">
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="50%"></b-skeleton>
                      </div>
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-4">
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="70%"></b-skeleton>
                      </div>
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-4">
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="50%"></b-skeleton>
                      </div>
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-4">
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="80%"></b-skeleton>
                      </div>
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-4">
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="80%"></b-skeleton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12 col-lg-12 col-xl-3">
                <div class="row actions">
                  <div class="col-12 mb-2">
                    <b-skeleton
                      type="button"
                      height="56px"
                      width="100%"
                    ></b-skeleton>
                  </div>
                  <div class="col-12 mb-2">
                    <b-skeleton
                      type="button"
                      height="56px"
                      width="100%"
                    ></b-skeleton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // Import Services
  import { adm_stagingService_GetAllSrByCustomerID } from '../../services/admin/staging';
  import { adm_userService_GetDetailsByID } from '../../services/admin/users';
  
  // Import Moment JS
  import moment from 'moment';
  
  // Import Common
  import { formatDate, formatPrice, formatUserName } from '../../common/Utility';
  export default {
    metaInfo() {
      return {
        title: 'Digital Staging - Users | Order History',
      };
    },
  
    data() {
      return {
        usersData: null,
        search: null,
        stagingListing: null,
        pagination: {
          data: null,
          limit: 3,
          showDisabled: false,
          size: 'default',
          align: 'center',
        },
      };
    },
  
    watch: {
      'pagination.limit'(newVal) {
        this.pagination.limit = parseInt(newVal);
  
        if (this.pagination.limit < 0) {
          this.pagination.limit = 0;
        }
      },
    },
  
    mounted() {
      this.getUserDetails();
      this.getResults();
    },
  
    methods: {
      formatDate,
      formatPrice,
      formatUserName,
      getResults(page) {
        let _this = this;
  
        let accessToken = localStorage.getItem('_accessToken');
        let customer_id = _this.$route.params.id;
        _this.stagingListing = null;
  
        adm_stagingService_GetAllSrByCustomerID({
          page: page ? page : 1,
          customer_id: customer_id,
          token: accessToken,
        })
          .then((response) => {
            let data = response.data.result;
            _this.stagingListing = data.data;
            _this.pagination.data = data;
            console.log('res', data);
          })
          .catch((error) => {
            console.log(error);
            _this.$bvToast.toast(
              'データの取得中にエラーが起こりました。再度お試しください。',
              {
                title: 'システムエラー',
                autoHideDelay: 5000,
                variant: 'danger',
                appendToast: true,
              }
            );
          });
        _this.$store.state.currentPaginationPage = page;
      },
      getUserDetails() {
        let _this = this;
        let accessToken = localStorage.getItem('_accessToken');
        adm_userService_GetDetailsByID({
          id: _this.$route.params.id,
          token: accessToken,
        })
          .then((response) => {
            _this.usersData = response.data.result;
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
  };
  </script>
  
  <style scoped>
  /* Init Style */
  /* Custom Style */
  #top-ation {
    display: flex;
  }
  
  @media only screen and (max-width: 767px) {
    #top-ation {
      display: block;
    }
  }
  
  #top-ation .search {
    display: flex;
    width: 100%;
    height: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border: 1px solid #d1d1d1;
  }
  
  @media only screen and (max-width: 767px) {
    #top-ation .search {
      margin-bottom: 14px;
    }
  }
  
  #top-ation .search .icon {
    font-size: 24px;
    padding: 10px;
    text-align: center;
    background: #fff;
    color: #d1d1d1;
  }
  
  #top-ation .search input {
    width: 100%;
    border: none;
    padding: 10px;
  }
  
  #top-ation .search input:focus {
    outline: none;
  }
  
  #staging-list .item {
    margin-bottom: 20px;
    border-radius: 20px;
    background: #f5f5f5;
  }
  
  @media only screen and (max-width: 1199px) {
    #staging-list .item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    }
  }
  
  @media only screen and (max-width: 1199px) {
    #staging-list .item .details {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
    }
  }
  
  #staging-list .item .details .img-carousel {
    height: 130px;
    background: #e9e9e9;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  
  @media only screen and (max-width: 1199px) {
    #staging-list .item .details .img-carousel {
      height: 350px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    #staging-list .item .details .img-carousel {
      height: 250px;
    }
  }
  </style>
  